<template src="./guestbook.html"></template>

<script>
import Http from '@/shared/http-config'
import { permissionCheck } from '@/shared/permission.js';
import SocialPostForm from '@/views/marketing/media-manager/details/Form.vue'

export default {
  name: "Guestbook",
  components: { SocialPostForm },
  data: function () {
    return {
      permissionCheck: permissionCheck,
      entries: [],
      meta: {
        current_page: 1,
        total: 0,
        per_page: 8
      },
    }
  },
  mounted: function(){
    this.$store.dispatch('setIntroJsEnabled', false);
  },
  destroyed: function(){
    this.$store.dispatch('setIntroJsEnabled', true);
  },
  created: function(){
    this.getEntries(1);
  },
  methods: {
    getEntries (page) {
      let self = this;
      const params = '?page=' + page + '&per_page=' + self.meta.per_page;

      Http.request('GET', '/guestbook' + params)
        .then(function (response) {
          self.entries = response.data;
          self.meta = response.meta;
        });
    },
    deleteEntry (id, key) {
      let self = this;
      let confirmHtml = self.$createElement('div', { domProps: {
        innerHTML: 'Möchtest du diesen Eintrag wirklich löschen?<br>Bitte beachte, dass ggf. angelegte Social Media Posts automatisch mit gelöscht werden.'
      }});
      self.$bvModal.msgBoxConfirm([confirmHtml], {
          headerBgVariant: 'primary',
          title: 'Eintrag löschen?',
          okTitle: 'Ja',
          cancelTitle: 'Nein',
          cancelVariant: 'outline-danger'
        })
        .then(answer => {
          if(answer) {
            Http.request('DELETE', '/guestbook/' + id).then(function () {
              self.entries.splice(key, 1)
            });
          }
        });
    },
    setEntryActive: function (entry) {
      this.$bvModal.msgBoxConfirm('Möchtest du diesen Eintrag ' + (entry.active ? 'offline' : 'online') + ' stellen?', {
          headerBgVariant: 'primary',
          title: 'Eintrag de/aktivieren?',
          okTitle: 'Ja',
          cancelTitle: 'Nein',
          cancelVariant: 'outline-danger'
        })
        .then(answer => {
          if(answer === true){
            let active = entry.active ? 0 : 1;
            Http.request('POST', '/guestbook/' + entry.id + '/active/' + active).then(() => {
              entry.active = active === 1;
            });
          }
        });
    },
    saveSocialPosts: function (entry) {
      let self = this;
      if(self.$refs['socialPosts-' + entry.id]){
        self.$refs['socialPosts-' + entry.id][0].store().then(function (){
          self.getEntries(1);
          self.$bvModal.hide('social-modal-' + entry.id);
        });
      }
    },
  }
}
</script>

<style scoped>
.card.disabled .card-title,
.card.disabled .card-text {
  color: #BABABA;
}

</style>
